import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useContext,
    useState,
    useEffect,
} from 'react';
import {Overlay} from 'components/customer/Product/QuickAdd/Overlay';
import {Controls} from 'components/customer/Product/QuickAdd/Controls';
import {ItemType} from 'shared/components/Product/Item';
import {useJobContext, useNotificationContext} from 'contexts';
import {PartialJob} from 'shared/types/PartialJob';
import {
    invalidateSidebarAndCost,
    useQuickAddProductMutation,
} from 'components/customer/Product/store/productApi';
import {useAppDispatch} from 'store/customer';
import {genericMessageHandler} from 'shared/helpers';
import {invalidateTotalProductCount} from 'components/customer/Job/store/jobApi';
import {isMobile} from 'react-device-detect';

interface QuickAddProps {
    onClickHandler: () => void;
    isFocused?: boolean;
    item?: ItemType;
}

type SetState<T> = Dispatch<SetStateAction<T>>;

interface QuickAddInterface extends QuickAddProps {
    open?: boolean;
    quantity?: number;
    setQuantity?: SetState<number>;
    width?: number;
    setWidth?: SetState<number>;
    addProduct?: () => Promise<void>;
    loading?: boolean;
    startAnimation?: boolean;
    onAnimationComplete?: () => void;
    validationMessage?: string;
    setValidationMessage?: SetState<string>;
    canRetry?: boolean;
}

export const QuickAddContext =
    React.createContext<QuickAddInterface>(undefined);

export const useQuickAddContext = () => useContext(QuickAddContext);

export const getInitialWidth = (item: ItemType) => {
    // this simply means cabinet is l-shaped
    // anything that is non 0 are l shaped cabinets
    if (item.lshape != 0) {
        return item.leftWidth;
    }

    return item.defaultWidth || 500;
};

export const QuickAdd = ({item, isFocused, onClickHandler}: QuickAddProps) => {
    const dispatch = useAppDispatch();

    const [quantity, setQuantity] = useState(1);
    const [width, setWidth] = useState(() => getInitialWidth(item));
    const [loading, setLoading] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');
    const [open, setOpen] = useState(isFocused);
    const [canRetry, setCanRetry] = useState(false);

    const {job, room, updateTotalProductCountManual} =
        useJobContext() as PartialJob;
    const {notify} = useNotificationContext();
    const [addProductManually] = useQuickAddProductMutation();

    const [startAnimation, setStartAnimation] = useState(false);
    const onAnimationComplete = useCallback(() => {
        setLoading(false);
        setStartAnimation(false);
    }, []);

    const resetValidationMessage = useCallback(() => {
        setValidationMessage('');
        setCanRetry(false);
    }, []);

    const addProduct = useCallback(async () => {
        setLoading(true);
        let noAnimation = false;

        if (isNaN(width) || width <= 0) {
            genericMessageHandler(notify, {
                message: 'Width must be greater than 0',
            });
            setLoading(false);
            return;
        }

        if (width < item.minWidth) {
            setValidationMessage(
                `Width must be greater than or equal to ${item.minWidth}`
            );
            setLoading(false);
            return;
        }

        if (isNaN(quantity) || quantity <= 0) {
            genericMessageHandler(notify, {
                message: 'Quantity must be greater than 0',
            });
            setLoading(false);
            return;
        }

        let retryAfter: number;
        try {
            const {error} = await addProductManually({
                cabinetType: item.id,
                room: room.id,
                width,
                quantity,
            });

            if (error) {
                if (typeof error === 'object' && 'data' in error) {
                    const {data} = error as {
                        data: {
                            messages?: string[];
                            error?: string;
                            retryAfter?: number;
                        };
                    };

                    if (data) {
                        if (
                            'messages' in data &&
                            Array.isArray(data.messages)
                        ) {
                            throw new Error(data.messages.join(', '));
                        }

                        if ('error' in data) {
                            if ('retryAfter' in data) {
                                retryAfter = data.retryAfter;
                            }

                            throw new Error(data.error);
                        }
                    }
                }

                throw new Error(
                    'Could not save product, please try manually adding it from product editor.'
                );
            }

            updateTotalProductCountManual(false, true, quantity);
            dispatch(invalidateSidebarAndCost());
            dispatch(invalidateTotalProductCount());

            genericMessageHandler(
                notify,
                {message: 'Product saved'},
                'success'
            );
        } catch (e) {
            let errorMessage = 'An unknown error occurred';
            if (e instanceof Error) {
                errorMessage = e.message;
            }
            setValidationMessage(errorMessage);

            if (isMobile) {
                genericMessageHandler(notify, {message: errorMessage});
            }

            if (typeof retryAfter != 'undefined') {
                setCanRetry(true);
                setTimeout(resetValidationMessage, retryAfter * 1000);
            }

            noAnimation = true;
        } finally {
            if (!noAnimation) {
                setStartAnimation(true);
            } else {
                setLoading(false);
            }
        }
    }, [
        job,
        quantity,
        width,
        item,
        addProductManually,
        updateTotalProductCountManual,
        isMobile,
    ]);

    useEffect(() => {
        if (validationMessage == '' || isMobile) {
            setOpen(isFocused);
        } else {
            setOpen(true);
        }
    }, [isFocused, validationMessage, isMobile]);

    return (
        <QuickAddContext.Provider
            value={{
                canRetry,
                isFocused,
                item,
                loading,
                open,
                onClickHandler,
                quantity,
                setQuantity,
                width,
                setWidth,
                addProduct,
                startAnimation,
                onAnimationComplete,
                validationMessage,
                setValidationMessage,
            }}>
            <div>
                <Overlay />
                <Controls />
            </div>
        </QuickAddContext.Provider>
    );
};
